
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"

export default {
  name: "RightSidebarContent",
  props: {
    title: {
      type: String,
      default: "",
    },
    containerClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mainContentHeight: "100%",
    }
  },
  computed: {
    ...mapStores(useLayoutStore),
    mainContentStyle() {
      return {
        height: `${this.mainContentHeight}px`,
      }
    },
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        const rightSidebarHeight =
          this.$globalRefs.rightSidebar?.getBoundingClientRect()?.height
        const rightSidebarHeaderHeight =
          this.$refs.rightSidebarHeader?.getBoundingClientRect()?.height
        const rightSidebarFooterHeight =
          this.$refs.rightSidebarHeader?.getBoundingClientRect()?.height
        this.mainContentHeight =
          rightSidebarHeight -
          (rightSidebarHeaderHeight + rightSidebarFooterHeight)
      })
    },
  },
}
