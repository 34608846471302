
import { IMAGE_TYPES } from "@evercam/shared/constants/ingest"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"
export default {
  props: {
    allowedTypes: {
      type: Array,
      default: () => IMAGE_TYPES,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedFileType: {
      type: String,
      default: "images",
    },
  },
  data: () => ({
    dragover: false,
    loading: false,
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore),
    totalSizeLabel() {
      return `Total Size: ${this.$units.formatBytes(
        this.totalSizeValue()
      )}`.toUpperCase()
    },
    displayAllowedTypes() {
      return this.allowedTypes.toString().replaceAll(",", ", ").toUpperCase()
    },
  },
  watch: {
    "ingestFileUploaderStore.files"(value) {
      if (!value || value.length == 0) {
        this.loading = false
      }
    },
  },
  methods: {
    addDropFile(e) {
      if (this.disabled) {
        return
      }
      this.selectAllowedFiles(e.dataTransfer.files)
    },
    onSelect() {
      this.selectAllowedFiles(this.ingestFileUploaderStore.files)
    },
    selectAllowedFiles(originalFiles) {
      let files = []
      //Check if this is not an array or list
      if (!Array.isArray(originalFiles) && !originalFiles.length) {
        originalFiles = [originalFiles]
      }
      Array.from(originalFiles).forEach((elt) => {
        if (this.isFileAllowed(elt)) {
          files.push(elt)
        }
      })
      this.ingestFileUploaderStore.files = files
      this.loading = true
      this.ingestFileUploaderStore.currentUploadStats.totalSize =
        this.totalSizeValue()
      this.$emit("files-updated", {
        size: this.ingestFileUploaderStore.currentUploadStats.totalSize,
      })
    },
    isFileAllowed(file) {
      return this.allowedTypes.includes(
        `.${file.name.split(".").pop().toLowerCase()}`
      )
    },
    totalSizeValue() {
      return this.ingestFileUploaderStore.files.reduce(
        (partialSize, item) => partialSize + item.size,
        0
      )
    },
  },
}
