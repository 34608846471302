
import Vue from "vue"
import { mapStores } from "pinia"
import { useBimCompareStore } from "@/stores/bimCompare"
import { useRecordingsStore } from "@/stores/recordings"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types"
import BimCompareExportDialog from "@/components/bimCompare/BimCompareExportDialog"

export default Vue.extend({
  name: "TheTimelineSidebarBimMilestones",
  components: { BimCompareExportDialog },
  data() {
    return {
      bimMilestonesCarousel: true,
    }
  },
  computed: {
    ...mapStores(useBimCompareStore, useRecordingsStore, useCameraStore),
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
  },
  methods: {
    loadBimModel() {
      this.$root.$emit("load-bim-etimeline-data")
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectModel, {
        model: this.bimCompareStore.model,
      })
    },
    toggleBimMilestonesVisibility() {
      this.bimMilestonesCarousel = !this.bimMilestonesCarousel
    },
    triggerChangeTransparencyEvent() {
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareChangeTransparency, {
        transparencyLevel: this.bimCompareStore.transparency,
      })
    },
  },
})
