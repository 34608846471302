
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Vue from "vue"
import { Snapshot } from "@evercam/shared/types/recording"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { camelizeKeys } from "humps"

export default Vue.extend({
  name: "BimViewer",
  props: {
    cameraExid: {
      type: String,
      default: "",
    },
    timeStamp: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    iframeStyle: {},
    cameraImage: "",
    transparentAreas: "",
    iframeReady: false,
  }),
  computed: {
    ...mapStores(useProjectStore),
    frameSource() {
      return `${this.$config.public.iTwinBimCompareIframeUrl}/?projectID=${this.projectStore.selectedProjectExid}&type=bim-compare&cameraID=${this.cameraExid}`
    },
    routeCameraExid() {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
  },
  watch: {
    timeStamp(value) {
      this.getBimMaskLayers(value)
    },
  },
  mounted() {
    window.onresize = this.updateFrameDimensions
    this.$addEventListener("message", this.handleReceiveMessage)
  },
  methods: {
    handleReceiveMessage(event) {
      if (
        event.origin === this.$config.public.iTwinBimCompareIframeUrl &&
        event.data?.type === "ready"
      ) {
        this.iframeReady = true
        this.getBimMaskLayers(this.timeStamp)
      }
    },
    updateFrameDimensions() {
      this.$clearTimeouts()
      this.$setTimeout(() => {
        this.getSnapshot()
        this.iframeStyle = {
          height: "100%",
          width: "100%",
        }
      }, 1000)
    },
    async getSnapshot() {
      let camDatetime: Snapshot = await EvercamApi.recordings.latest(
        this.routeCameraExid
      )
      this.cameraImage = camDatetime.data
    },
    sendTransparencyAreas() {
      if (this.iframeReady) {
        this.$refs.iframeCesium?.contentWindow?.postMessage(
          {
            type: "transparentArea",
            areas: this.transparentAreas,
          },
          this.$config.public.iTwinBimCompareIframeUrl
        )
      }
    },
    getBimMaskLayers(date) {
      EvercamApi.layers
        .getLayer(this.cameraExid, {
          timestamp: this.timeStamp,
          layerType: LAYER_TYPES.BIM_MASK,
        })
        .then((response) => {
          if (
            !response.data.shapes ||
            new Date(date).getTime() < new Date(response.data.startAt).getTime()
          ) {
            this.shapes = []

            return
          }
          this.transparentAreas = response.data.shapes
          this.sendTransparencyAreas()
        })
        .catch((error) => {
          console.log(error)
          if (error.response?.status !== 404) {
            this.$errorTracker.save(error)
          }
        })
    },
  },
})
