
import VueDragResize from "vue-drag-resize"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { useSnapshotStore } from "@/stores/snapshots"
import { mapStores } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { useRecordingsStore } from "@/stores/recordings"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default {
  name: "RecordingsPlayerXrayOverlay",
  components: {
    VueDragResize,
    EvercamLoadingAnimation,
  },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    initialParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      positionTop: 0,
      positionLeft: 0,
      positionHeight: 0,
      positionWidth: 0,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      isInitialized: false,
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useRecordingsStore,
      useCameraStore,
      useAccountStore
    ),
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    requestQuery() {
      if (this.$route.query.api_id && this.$route.query.api_key) {
        return `?api_id=${this.$route.query.api_id}&api_key=${this.$route.query.api_key}`
      } else {
        return ""
      }
    },
    cssVars() {
      return {
        clip: `rect(${this.positionTop}px,${
          this.positionLeft + this.positionWidth
        }px,${this.positionTop + this.positionHeight}px,${
          this.positionLeft
        }px)`,
      }
    },
    formattedXrayTimestamp() {
      return this.$moment
        .tz(
          this.recordingsStore.selectedXrayTimestamp || new Date(),
          this.timezone
        )
        .format("DD-MM-YYYY HH:mm:ss")
    },
  },
  watch: {
    height: {
      immediate: true,
      handler() {
        this.$setTimeout(
          () => {
            let xrayContainerHeight
            if (this.$vuetify.breakpoint.smAndDown) {
              xrayContainerHeight = 200
            } else {
              xrayContainerHeight = 250
            }
            if (this.height > 250) {
              this.y = (this.height - xrayContainerHeight) / 2
            } else if (this.height > 0) {
              this.y = 5
              this.h = this.height - 50
            }
            this.positionHeight = this.h
            this.positionTop = this.y
          },
          this.$device.isMobile ? 100 : 0
        )
      },
    },
    width: {
      immediate: true,
      handler() {
        this.$setTimeout(
          () => {
            let xrayContainerWidth
            if (this.$vuetify.breakpoint.smAndDown) {
              xrayContainerWidth = 200
            } else {
              xrayContainerWidth = 300
            }
            if (this.width > 300) {
              this.x = (this.width - xrayContainerWidth) / 2
            } else if (this.width > 0) {
              this.x = 5
              this.w = this.width - 50
            }
            this.positionWidth = this.w
            this.positionLeft = this.x
          },
          this.$device.isMobile ? 100 : 0
        )
      },
    },
    "recordingsStore.selectedXrayTimestamp"(timestamp, previousTimestamp) {
      if (!timestamp || timestamp === previousTimestamp) {
        return
      }

      this.fetchXraySnapshot()
    },
    "cameraStore.selectedCameraExid"() {
      this.handleClose()
    },
  },
  created() {
    this.fetchXraySnapshot()
    if (!this.positionTop) {
      this.positionTop = (this.height - 250) / 2
    }
    if (!this.positionLeft) {
      this.positionLeft = (this.width - 200) / 2
    }
    if (!this.positionHeight) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.positionHeight = 200
      } else {
        this.positionHeight = 250
      }
    }
    if (!this.positionWidth) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.positionWidth = 200
      } else {
        this.positionWidth = 300
      }
    }
    if (!this.x) {
      this.x = (this.width - 200) / 2
    }
    if (!this.y) {
      this.y = (this.height - 250) / 2
    }
    if (!this.w) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.w = 200
      } else {
        this.w = 300
      }
    }
    if (!this.h) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.h = 200
      } else {
        this.h = 250
      }
    }
  },
  mounted() {
    this.$root.$on("generate-xray-image", this.generateXrayImage)
    this.getPosition()
  },
  beforeDestroy() {
    this.handleClose()
  },
  methods: {
    async initXrayTimestamp() {
      try {
        const nearestMilestone = await EvercamApi.layers.getLayer(
          this.cameraStore.selectedCameraExid,
          {
            timestamp: this.snapshotStore.latestSnapshotTimestamp,
            layerType: LAYER_TYPES.MILESTONE,
          }
        )
        this.recordingsStore.selectedXrayTimestamp = nearestMilestone?.startAt
      } catch (error) {
        this.recordingsStore.selectedXrayTimestamp =
          this.snapshotStore.oldestSnapshotTimestamp
        console.error(
          "Unable to retrieve milestone layers for camera",
          this.cameraStore.selectedCameraExid,
          error
        )
      } finally {
        this.recordingsStore.selectedXrayTimestamp = this.$moment
          .tz(
            this.recordingsStore.selectedXrayTimestamp || new Date(),
            this.timezone
          )
          .startOf("hour")
          .format()
      }
    },
    stopXrayLoading() {
      this.$nextTick(() => (this.recordingsStore.isXrayLoading = false))
    },
    async fetchXraySnapshot() {
      this.recordingsStore.isXrayLoading = true
      if (!this.isInitialized && !this.recordingsStore.selectedXrayTimestamp) {
        this.isInitialized = true
        await this.initXrayTimestamp()
      }
      await this.recordingsStore.fetchXraySnapshot(
        this.recordingsStore.selectedXrayTimestamp,
        this.cameraStore.selectedCameraExid
      )
    },
    getPosition(options, eventId = null) {
      if (eventId === "dragstop") {
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.XrayMove,
        })
      }
      if (options) {
        this.positionTop = options.top
        this.positionLeft = options.left
      }
    },
    onResize(options) {
      if (options) {
        this.positionTop = options.top
        this.positionLeft = options.left
        this.positionWidth = options.width
        this.positionHeight = options.height
        this.h = options.height
        this.w = options.width
        this.y = options.top
        this.x = options.left
      }
    },
    handleClose() {
      this.recordingsStore.changeXrayVisibility(false)
      this.recordingsStore.selectedXraySnapshot = null
      this.recordingsStore.selectedXrayTimestamp = null
    },
    handleCloseMobile() {
      if (this.$device.isMobile) {
        this.recordingsStore.changeXrayVisibility(false)
      }
    },
    generateXrayImage() {
      const { selectedXraySnapshot } = this.recordingsStore

      const beforeSnapshot = new Image()
      beforeSnapshot.setAttribute("crossorigin", "anonymous")
      const afterSnapshot = new Image()
      afterSnapshot.setAttribute("crossorigin", "anonymous")

      afterSnapshot.src = this.recordingsStore.getCurrentImageSrc()
      afterSnapshot.addEventListener("load", () => {
        const { width: afterSnapshotWidth, height: afterSnapshotHeight } =
          afterSnapshot

        const canvas = document.createElement("canvas")
        canvas.width = afterSnapshotWidth
        canvas.height = afterSnapshotHeight
        const ctx = canvas.getContext("2d")
        ctx.drawImage(afterSnapshot, 0, 0)

        beforeSnapshot.src = selectedXraySnapshot
        beforeSnapshot.addEventListener("load", () => {
          const { width: beforeSnapshotWidth, height: beforeSnapshotHeight } =
            beforeSnapshot
          const {
            positionTop,
            positionLeft,
            $refs: { xrayImg },
          } = this

          const xrayZoomableImageContainer = xrayImg.$el.getBoundingClientRect()
          const xrayImage = xrayImg.$refs.image.getBoundingClientRect()

          const offsetXrayImageTopPosition =
            positionTop - (xrayImage.top - xrayZoomableImageContainer.top)
          const offsetXrayImageLeftPosition =
            positionLeft - (xrayImage.left - xrayZoomableImageContainer.left)

          // Xray square top and left position
          const normalizedXrayImageTopPosition =
            offsetXrayImageTopPosition / xrayImage.height
          const normalizedXrayImageLeftPosition =
            offsetXrayImageLeftPosition / xrayImage.width

          // Xray square width and height
          const normalizedXrayImageWidth = this.w / xrayImage.width
          const normalizedXrayImageHeight = this.h / xrayImage.height

          ctx.drawImage(
            beforeSnapshot,
            // Xray square position and with when cropping from beforeSnapshot
            normalizedXrayImageLeftPosition * beforeSnapshotWidth,
            normalizedXrayImageTopPosition * beforeSnapshotHeight,

            normalizedXrayImageWidth * beforeSnapshotWidth,
            normalizedXrayImageHeight * beforeSnapshotHeight,

            // Xray square position and with when drawing on canvas
            normalizedXrayImageLeftPosition * afterSnapshotWidth,
            normalizedXrayImageTopPosition * afterSnapshotHeight,

            normalizedXrayImageWidth * afterSnapshotWidth,
            normalizedXrayImageHeight * afterSnapshotHeight
          )

          ctx.setLineDash([5, 3])
          ctx.lineWidth = 2
          ctx.beginPath()
          ctx.rect(
            normalizedXrayImageLeftPosition * afterSnapshotWidth,
            normalizedXrayImageTopPosition * afterSnapshotHeight,

            normalizedXrayImageWidth * afterSnapshotWidth,
            normalizedXrayImageHeight * afterSnapshotHeight
          )
          ctx.strokeStyle = "white"
          ctx.stroke()

          // text background
          const textBackgroundRectangleHeight = afterSnapshotHeight * 0.03
          const textBackgroundRectangleWidth = textBackgroundRectangleHeight * 7
          ctx.fillStyle = "rgba(25, 118, 210,0.7)"
          ctx.fillRect(
            normalizedXrayImageLeftPosition * afterSnapshotWidth,
            normalizedXrayImageTopPosition * afterSnapshotHeight,
            textBackgroundRectangleWidth - 40, //minus the cross button width
            textBackgroundRectangleHeight
          )

          // Timestamp text
          const textFontSize = afterSnapshotHeight * 0.02
          ctx.fillStyle = "white"
          ctx.font = `${textFontSize}px roboto`
          ctx.fillText(
            this.formattedXrayTimestamp,
            normalizedXrayImageLeftPosition * afterSnapshotWidth +
              textFontSize / 5,
            normalizedXrayImageTopPosition * afterSnapshotHeight + textFontSize
          )

          this.recordingsStore.exportedXrayImage =
            canvas.toDataURL("image/jpeg")
          this.$root.$emit("xray-image-generated")
        })
      })
    },
  },
}
