import { render, staticRenderFns } from "./RecordingsPlayerCommentsOverlay.vue?vue&type=template&id=1135d8ec"
import script from "./RecordingsPlayerCommentsOverlay.vue?vue&type=script&lang=ts"
export * from "./RecordingsPlayerCommentsOverlay.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports