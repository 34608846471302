
import { downloadImage, getIOSMediaUrl } from "@evercam/shared/utils"
import ActionButton from "@evercam/shared/components/ActionButton"
import IosMediaDialog from "@evercam/shared/components/medias/IosMediaDialog"
import WatermarkedImageDownload from "@evercam/shared/components/WatermarkedImageDownload"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useWeatherStore } from "@/stores/weather"
import { useRecordingsStore } from "@/stores/recordings"
import { useCameraStore } from "@/stores/camera"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { useTimelineCommentsGroupStore } from "@/stores/timeline/timelineCommentsGroup"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { useTimelineObjectInspectorGroupStore } from "@/stores/timeline/timelineObjectInspectorGroup"

export default {
  name: "ActionButtons",
  components: {
    ActionButton,
    IosMediaDialog,
    WatermarkedImageDownload,
  },
  props: {
    showDownload: {
      type: Boolean,
      default: true,
    },
    showPlayback: {
      type: Boolean,
      default: false,
    },
    showFullscreen: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    showWeather: {
      type: Boolean,
      default: false,
    },
    showXray: {
      type: Boolean,
      default: false,
    },
    showComment: {
      type: Boolean,
      default: false,
    },
    showInspector: {
      type: Boolean,
      default: false,
    },
    showCompare: {
      type: Boolean,
      default: false,
    },
    showCalendar: {
      type: Boolean,
      default: false,
    },
    selectedCamera: {
      type: Object,
      default: () => ({}),
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: true,
    },
    selectedTimestamp: {
      type: [String, Date],
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSpecialAction: false,
      specialKeys: ["Shift", "Control"],
      iosMediaDialog: false,
      iosMediaUrl: "",
    }
  },
  computed: {
    ...mapStores(
      useRecordingsStore,
      useCameraStore,
      useLayoutStore,
      useWeatherStore,
      useTimelineStore,
      useTimelineCommentsGroupStore,
      useTimelineObjectInspectorGroupStore
    ),
    showMenuButton() {
      return (
        this.showDownload ||
        this.showPlayback ||
        this.showFullscreen ||
        this.showEdit ||
        this.showWeather ||
        this.showXray ||
        this.showComment ||
        this.showInspector ||
        this.showCompare ||
        this.showCalendar
      )
    },
    snapshotDownloadTitle() {
      const datetime = this.$moment(this.selectedTimestamp).tz(
        this.selectedCamera.timezone
      )
      const date = datetime.format("YYYY-MMMM-DD")
      const time = datetime.format("HH-mm")

      return `${this.selectedCamera.name}--${date}--${time} - Evercam Download`
    },
    setFullscreenIcon() {
      if (this.$device.isIos) {
        return "fas fa-expand"
      } else {
        return this.layoutStore.isFullscreen
          ? "fas fa-minimize"
          : "fas fa-expand-arrows-alt"
      }
    },
  },
  mounted() {
    this.$addEventListener("keydown", this.handleKeyDown)
    this.$addEventListener("keyup", this.handleKeyUp)
  },
  methods: {
    toggleAnnotations() {
      this.timelineCommentsGroupStore.allowComment(
        !this.timelineCommentsGroupStore.isAnnotationActive
      )
    },
    toggleObjectInspector() {
      this.timelineObjectInspectorGroupStore.toggleInspector()
    },
    getSnapshotDownloadUrl() {
      return this.recordingsStore.getCurrentImageSrc()
    },
    openWeatherDialog() {
      this.$fullscreen.exit()
      this.weatherStore.targetTimestamp = this.selectedTimestamp

      this.weatherStore.changeWeatherVisibility(true)
    },
    toggleIOSFullscreen() {
      this.cameraStore.isCameraTab = !this.cameraStore.isCameraTab
      this.layoutStore.toggleAppBarVisibility()
    },
    toggleFullscreen() {
      this.$fullscreen.toggle(this.$globalRefs.playerWrapper)
      this.$analytics.saveEvent(AnalyticsEvent.PlayerToggleFullscreen, {
        active: !this.layoutStore.isFullscreen,
      })
    },
    async downloadSnapshot(disableWatermark = false) {
      if (this.$device.isIos) {
        this.iosMediaUrl = await getIOSMediaUrl(this.getSnapshotDownloadUrl())
        this.iosMediaDialog = true
      } else if (disableWatermark || this.$device.isIE) {
        downloadImage(this.getSnapshotDownloadUrl(), this.snapshotDownloadTitle)
      } else {
        await this.downloadWatermarkedImage()
      }

      this.recordingsStore.isDownloading = false
      this.$analytics.saveEvent(AnalyticsEvent.PlayerDownload)
    },

    toggleXray() {
      this.recordingsStore.changeXrayVisibility(
        !this.recordingsStore.isXrayActive
      )
    },
    async downloadWatermarkedImage() {
      this.recordingsStore.isDownloading = true
      try {
        const image = await fetch(this.getSnapshotDownloadUrl()).then((res) =>
          res.blob()
        )
        const type = image?.type?.split("/")?.[1]
        const downloadTime = this.$moment()
          .tz(this.cameraStore.selectedCamera?.timezone)
          .format("llll")

        this.$root.$emit("download-watermarked-image", {
          image,
          title: `${this.snapshotDownloadTitle}.${type}`,
          watermarkText: `Camera Name: ${this.cameraStore.selectedCamera?.name} | Downloaded On: ${downloadTime}`,
        })
      } catch (error) {
        downloadImage(
          this.getSnapshotDownloadUrl(),
          this.snapshotDownloadTitle,
          ""
        )
      } finally {
        this.recordingsStore.isDownloading = false
      }
    },
    handleKeyDown(e) {
      if (this.specialKeys.includes(e.key)) {
        this.isSpecialAction = true
      }
    },
    handleKeyUp(e) {
      if (this.specialKeys.includes(e.key)) {
        this.isSpecialAction = false
      }
    },
    toggleCalendar() {
      this.layoutStore.enableRightSidebar()
      this.$fullscreen.exit()
    },
    toggleCurrentPlayer() {
      this.$analytics.saveEvent(AnalyticsEvent.PlayerTogglePlayback, {
        active: !this.isPlaying,
      })
      this.$attrs.toggleCurrentPlayer()
    },
  },
}
