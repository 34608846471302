
import { ALLOWED_BIM_TYPES } from "@evercam/shared/constants/ingest"
import FileBox from "@/components/ingest/FileBox"
import * as tus from "tus-js-client/dist/tus.min.js"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"

export default {
  components: {
    FileBox,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    projectId: {
      type: String,
      default: "",
    },
    projectName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    uploadedUrls: [],
    allowedTypes: ALLOWED_BIM_TYPES,
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore),
    areFilesEmpty() {
      return this.ingestFileUploaderStore.files.length === 0
    },
  },
  mounted() {
    this.ingestFileUploaderStore.files = []
  },
  methods: {
    onFilesUpdated({ size }) {
      if (this.ingestFileUploaderStore.files.length >= 0) {
        if (this.ingestFileUploaderStore.files.length > 50) {
          this.$notifications.error({
            text: "Max number of files allowed is 50!",
            error: {},
          })
          this.clearForm()

          return
        }
        //10737418240Bytes = 10 GB
        if (size > 10737418240) {
          this.$notifications.error({
            text: "Max size for files is 10GB!",
            error: {},
          })
          this.clearForm()

          return
        }
      }
    },
    async processUpload() {
      if (this.areFilesEmpty) {
        this.$notifications.error("Please provide files to upload")

        return
      }

      try {
        this.ingestFileUploaderStore.currentUploadStats.isUploading = true
        this.ingestFileUploaderStore.currentUploadStats.percentage = 0
        this.$emit("upload-started")
        for (let i = 0; i < this.ingestFileUploaderStore.files.length; i++) {
          this.uploadedUrls.push(
            await this.uploadFile(this.ingestFileUploaderStore.files[i])
          )
        }
        this.uploadToIngest()
      } catch (e) {
        this.$notifications.error(`Couldn't upload files : ${e}`)
        this.ingestFileUploaderStore.currentUploadStats = null
      }
    },
    async uploadFile(file) {
      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
          endpoint: `${this.$config.public.portalURL}`,
          chunkSize: Infinity,
          retryDelays: [0, 1000, 3000, 5000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          parallelUploads: 1,
          onProgress: (bytesUploaded) => {
            this.ingestFileUploaderStore.currentUploadStats.percentage = (
              ((this.ingestFileUploaderStore.currentUploadStats.uploadedSize +
                bytesUploaded) /
                this.ingestFileUploaderStore.currentUploadStats.totalSize) *
              100
            ).toFixed(2)
          },
          onSuccess: () => {
            this.ingestFileUploaderStore.currentUploadStats.uploadedSize +=
              file.size
            this.ingestFileUploaderStore.currentUploadStats.percentage = (
              (this.ingestFileUploaderStore.currentUploadStats.uploadedSize /
                this.ingestFileUploaderStore.currentUploadStats.totalSize) *
              100
            ).toFixed(2)

            file.uploadPercentage = "100"
            const [title, fileExtension] = upload.file.name.split(".")
            resolve({
              url: upload.url,
              title,
              fileExtension,
            })
          },
          onError: (error) => {
            console.log("Failed because: " + error)
            reject(error)
          },
        })
        upload.start()
      })
    },
    async uploadToIngest() {
      this.ingestFileUploaderStore.currentUploadStats.isProcessing = true
      this.ingestFileUploaderStore.currentUploadStats.isUploading = false
      try {
        await IngestApi.bim.upload(
          this.projectId,
          {
            id: this.id,
            name: this.projectName,
          },
          {
            upload: this.uploadedUrls,
          }
        )
      } catch (e) {
        this.$notifications.error({ text: "Error uploading images", error: e })
      } finally {
        this.ingestFileUploaderStore.currentUploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
        }
        this.uploadedUrls = []
        this.$emit("upload-completed")
      }
    },
    clearForm() {
      this.ingestFileUploaderStore.files = []
      this.floor = ""
      this.date = ""
    },
  },
}
